import sal from "sal.js";
import { uaCheck } from "./modules/utils";

uaCheck();

// PW
const PW = "dG93ZXIxMWJhcg==";
const SESSION_KEY = "sU8eHkDu";
const SESSION_VALUE = "uR8SViNa";

// セッションチェック
const sessionCheck = () => {
  const session = sessionStorage.getItem(SESSION_KEY);
  if (session === SESSION_VALUE) {
    window.location = "/main/";
  }
};

// セッションチェック(メイン)
const sessionCheckMain = () => {
  const session = sessionStorage.getItem(SESSION_KEY);
  if (session !== SESSION_VALUE) {
    window.location = "/";
  }
};

// ログインチェック
const loginCheck = () => {
  const loginBtn = document.getElementById("loginButton");
  const inputPw = document.getElementById("inputPw");
  const toast = document.querySelector("#errArt");

  loginBtn.addEventListener("click", () => {
    if (window.btoa(inputPw.value) === PW) {
      sessionStorage.setItem("sU8eHkDu", "uR8SViNa");
      window.location = "/main/";
    } else {
      sessionStorage.clear();
      toast.classList.add("show");
      setTimeout(() => {
        toast.classList.remove("show");
      }, 3000);
    }
  });
};

sal();

// トップのみ
if (document.getElementById("loginPage") !== null) {
  loginCheck();
  sessionCheck();
}

// メインのみ
if (document.getElementById("mainPage") !== null) {
  sessionCheckMain();

  // ロード後
  window.addEventListener("load", () => {
    const overLay = document.querySelector(".ov");
    const logo = document.querySelector("#logo");
    const scrIcon = document.querySelector("#scrIcon");
    const wallPaper = document.querySelector("#wallpaper");
    const entry = document.querySelector(".entry-button");
    const height = window.innerHeight / 2;

    setTimeout(() => {
      if (wallPaper) {
        wallPaper.classList.add("show");
      }
    }, 500);

    setTimeout(() => {
      if (logo) {
        logo.classList.add("show");
      }
    }, 1200);

    setTimeout(() => {
      if (entry) {
        entry.classList.add("show");
      }
    }, 1800);

    // スクロールするたびにスクロール量を出力
    window.addEventListener("scroll", () => {
      let scrollYY = window.scrollY;
      if (overLay) {
        overLay.style.opacity = scrollYY / height;
      }
      if (scrIcon) {
        if (scrollYY > 10) {
          scrIcon.classList.add("hidden");
        } else {
          scrIcon.classList.remove("hidden");
        }
      }
    });
  });
}

// 規約のみ
if (document.getElementById("subPage") !== null) {
  sessionCheckMain();
}
